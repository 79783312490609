import { NTheme } from 'kudi-component-library';
import { Suspense, useEffect } from 'react';
import { Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import Success from '../assets/success';
import Modal from './Modal';
import { AppBody, AppHeaderText, InfoText } from './styles';
import Loader from './Loader';
import { formatAmount } from '../utils';

export default function SuccessResponse({
  redirectUrl,
  amount,
  currency,
}: {
  redirectUrl: string | undefined;
  amount: number | undefined;
  currency?: string;
}) {
  const handleClose = () => {
    window.close();
  };

  useEffect(() => {
    setTimeout(() => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }, 1000);
  }, [redirectUrl]);

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
          <Suspense fallback={<Loader />}>
            <Modal showModal={true} onClose={handleClose}>
              <Flex
                bg="#FFFFFF"
                px="20px"
                py="60px"
                style={{ borderRadius: '4px' }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Success />
                <AppHeaderText mt="20px">Successful payment</AppHeaderText>
                <InfoText textAlign="center" mt="20px">
                  We have confirmed your payment of
                </InfoText>{' '}
                <br />
                <InfoText textAlign="center" color="n-dark" fontWeight={700}>
                  {amount && `${currency} ${formatAmount(amount)}`}
                </InfoText>
              </Flex>
            </Modal>
          </Suspense>
        </Flex>
      </AppBody>
    </ThemeProvider>
  );
}
