import { Button, NTheme } from "kudi-component-library";
import { Suspense } from "react";
import { Flex } from 'rebass'
import { ThemeProvider } from "styled-components";
import Modal from './Modal'
import { AppBody, AppHeaderText, InfoText } from './styles'
import ErrorPageIcon from "../assets/error-page";
import Loader from "./Loader";

export default function OrderNotFound({ error }: { error?: string }) {
    const handleClose = () => {
        window.close();
    }

    const goHome = () => {
        window.location.href = 'https://nomba.com';
      };
    return (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
                    <Suspense fallback={<Loader/>}>
                        <Modal showModal={true} onClose={handleClose}>
                            <Flex bg="#FFFFFF" px="20px" py="60px" style={{ borderRadius: "4px" }} flexDirection="column" justifyContent="center" alignItems="center">
                            <ErrorPageIcon />
                                <AppHeaderText mt="20px">Order Not Found</AppHeaderText>
                        
                                    <InfoText textAlign="center" mt="20px" mb="20px">{error} </InfoText>

                                    <Button onClick={() => goHome()} variant="medium" width="100%" type="button" >
                                        Go to Homepage
                                    </Button>
                            </Flex>
                        </Modal>
                    </Suspense>
                </Flex>
            </AppBody>
        </ThemeProvider>
    )
}
