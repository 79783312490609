import { NTheme } from 'kudi-component-library';
import { Suspense, useState } from 'react';
import { Button, Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import Modal from '../../Modal';
import { AppBody, AppHeaderText, ButtonText, InfoText } from '../../styles';
import { ButtonsControl, ErrorMessage } from '../style';
import FailedIcon from '../../../assets/failed';
import CloseModalNew from '../../../assets/closemodal-new';
import { useParams } from 'react-router-dom';
import SuccessNewIcon from '../../../assets/success-new';
import ConfirmModal from '../../ConfirmModal';
import { Orderdetails } from '../../../redux/slice';
import { handleCallbackUrl } from '../../../utils';
import { Star } from '../../../assets';
import Loader from '../../Loader';

export default function FailedResponse({
  error,
  orderDetails,
}: {
  error?: string;
  orderDetails?: Orderdetails | null;
}) {
  const { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleTryAgain = () => {
    window.location.href = `/pay/${id}`;
  };

  const checkErrType = (err: string) => {
    if (err.toLowerCase().includes('completed')) {
      return true;
    }
    return false;
  };

  const confirm = () => {
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Suspense fallback={<Loader/>}>
            <Modal showModal={true} onClose={handleClose}>
              <Flex justifyContent="flex-end" mb="10px">
                <CloseModalNew onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }} />
                {showModal && <ConfirmModal confirm={confirm} showModal={showModal} handleClose={handleClose} />}
              </Flex>
              <Flex
                bg="#FFFFFF"
                height="100%"
                style={{ borderRadius: '16px' }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {error && checkErrType(error) ? <SuccessNewIcon /> : <FailedIcon />}

                <AppHeaderText mt="20px">
                  {error && checkErrType(error) ? 'Payment already completed' : 'Your Payment Failed'}
                </AppHeaderText>

                <ErrorMessage>
                  <InfoText fontSize="16px" textAlign="center">
                    {error}
                  </InfoText>
                </ErrorMessage>

                {error && !checkErrType(error) && (
                  <ButtonsControl>
                    <Button
                      onClick={handleTryAgain}
                      textAlign="center"
                      bg="#FFCC00"
                      color="#121212"
                      variant="large"
                      width="100%"
                      height="50px"
                      className="cursor-pointer"
                      style={{ borderRadius: '8px' }}
                    >
                      <ButtonText style={{ whiteSpace: 'nowrap' }}>Change payment method</ButtonText>
                    </Button>
                    <Button
                      onClick={handleTryAgain}
                      textAlign="center"
                      bg="#FFFFFF"
                      color="#121212"
                      variant="large"
                      width="100%"
                      height="50px"
                      className="cursor-pointer"
                      style={{ border: '1px solid #E6E6E6', borderRadius: '8px' }}
                    >
                      <ButtonText>Try another card</ButtonText>
                    </Button>
                  </ButtonsControl>
                )}
              </Flex>

              <div
                style={{
                  marginTop: '100px',
                  padding: '8px',
                  background: 'linear-gradient(to right, #FFF5CC, #FFFCF2)',
                  borderRadius: '8px',
                  fontSize: '12',
                  lineHeight: '1.5',
                  border: '1px solid #FFEB99',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Star />
                </div>
                <div
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '25px',
                  }}
                >
                  Experience seamless transactions and earn rewards effortlessly with Nomba. Fast, secure, and
                  rewarding—get more with the Nomba app!
                  <a
                    href="https://app.nom.ba/V9Md/ch"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Sign up to Nomba now
                  </a>
                </div>
              </div>
            </Modal>
          </Suspense>
        </Flex>
      </AppBody>
    </ThemeProvider>
  );
}
