import { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { useParams } from 'react-router-dom';
import {
  LeftPane,
  MerchantDetails,
  MerchantLogo,
  MobileFooter,
  MobileHeader,
  MobilePaymentMethod,
  MobilePaymentMethods,
  MobilePaymentWrapper,
  PaymentMethodItem,
  PaymentMethods,
  PaymentWrapper,
  RightPane,
} from '../v2/style';
import { Avatar, Typography } from 'kudi-component-library';
import TransferActiveIcon from '../../assets/transfer-active';
import AtmCardInActiveIcon from '../../assets/card-inactive';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getSandboxOrderDetails } from '../../redux/action';
import ModalV2 from '../ModalV2';
import { formatAmount, ScreenTypes } from '../../utils';
import TransferInActiveIcon from '../../assets/transfer-inactive';
import AtmCardActiveIcon from '../../assets/card-active';
import PhoneSuccess from '../v2/Responses/PhoneSuccess';
import { ChevronRight } from '../../assets';
import FooterLogoDark from '../../assets/footer-logo-dark';
import Card from './Card/Card';
import { errorResponse } from '../../utils/toast';
import { IOrderDetails } from '../../redux/slice';
import SuccessResponse from './Responses/SuccessResponse';
import FailedResponse from './Responses/FailedResponse';
import { nombaCheckout } from '../../utils/featureFlags';

interface IModalProps {
  showModal: boolean;
}

export default function SandboxCheckoutModal({ showModal }: IModalProps) {
  const [active, setActive] = useState<number>(1);
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [sanboxOrder, setSandboxOrder] = useState<IOrderDetails | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { orderDetails } = useSelector((state: RootState) => state.order);
  const { id } = useParams();
  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>('Main');
  const [errorMsg, setErrMsg] = useState<string>('');

  const fetchOrderDetails = async (val: string) => {
    try {
      const result = await dispatch(getSandboxOrderDetails({ orderId: val }));
      if (result.payload.code === '00') {
        setSandboxOrder(result.payload);
        setLoading(false);
      } else {
        errorResponse(result.payload.description);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        errorResponse(error.message);
      } else {
        errorResponse('An unknown error occurred');
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchOrderDetails(id);
    }
  }, [dispatch, id]);

  const getInitials = (name: string | undefined | null) => {
    if (!name) return '';
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i][0]?.toUpperCase();
    }

    return initials;
  };

  const onMoveToNewScreen = (newscreen: ScreenTypes, error: string) => {
    setCurrentScreen(newscreen);
    if (error !== '') {
      setErrMsg(error);
    }
  };

  const changePaymentMethod = () => {
    setShowMobile(false);
  };

  return (
    <>
      {currentScreen === 'Main' && (
        <ModalV2 showModal={showModal} onClose={() => window.close()}>
          {loading ? (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="auto"
              mb="auto"
              style={{ height: '100%' }}
            >
              <Loader />
            </Flex>
          ) : nombaCheckout.enabled ? (
            <>
              <PaymentWrapper>
                <LeftPane>
                  <MerchantDetails>
                    <Flex alignItems="center" style={{ gap: '10px' }}>
                      <MerchantLogo>
                        <Avatar
                          size="medium"
                          initials={getInitials('Nomba Sandbox')}
                          imgUrl={`https://ui-avatars.com/api/?name=Nomba Test&background=FFF5CC&color=665200rounded=true`}
                        />
                      </MerchantLogo>
                      <Flex flexDirection="column">
                        <Typography fontSize="12px" fontWeight={700} className="ellipsis">
                          Nomba Test
                        </Typography>
                      </Flex>
                    </Flex>
                  </MerchantDetails>

                  <MobileHeader>
                    <MerchantLogo>
                      <Avatar
                        size="medium"
                        initials={getInitials('Nomba Sandbox')}
                        imgUrl={`https://ui-avatars.com/api/?name=Nomba Sandbox&background=FFF5CC&color=665200rounded=true`}
                      />
                    </MerchantLogo>
                    <Flex flexDirection="column">
                      <Typography fontSize="18px" fontWeight={500}>
                        {' '}
                        <span className="subtext">Pay</span> {sanboxOrder?.data?.order?.currency}{' '}
                        {formatAmount(sanboxOrder?.data?.order?.amount)}
                        <Typography fontSize="14px" color="n-grey3">
                          {sanboxOrder?.data?.order?.customerEmail}
                        </Typography>
                      </Typography>
                    </Flex>
                  </MobileHeader>

                  <PaymentMethods>
                    <Typography
                      fontSize="12px"
                      color="n-grey4"
                      fontWeight={500}
                      style={{ marginBottom: '15px', paddingLeft: '10px' }}
                    >
                      PAYMENT METHODS
                    </Typography>

                    <PaymentMethodItem active={active === 1 ? true : false} onClick={() => setActive(1)}>
                      {active === 1 ? <AtmCardActiveIcon /> : <AtmCardInActiveIcon />}
                      <Typography fontSize="14px" color={`${active === 1 ? 'dark' : 'n-grey4'}`} fontWeight={500}>
                        Card
                      </Typography>
                    </PaymentMethodItem>
                  </PaymentMethods>
                </LeftPane>
                <RightPane>
                  {active === 1 && (
                    <Card
                      changePaymentMethod={changePaymentMethod}
                      onMoveToNewScreen={onMoveToNewScreen}
                      orderDetails={sanboxOrder}
                    />
                  )}
                </RightPane>
              </PaymentWrapper>

              <MobilePaymentWrapper>
                <MobileHeader>
                  <MerchantLogo>
                    <Avatar
                      size="medium"
                      initials={getInitials('Nomba Sandbox')}
                      imgUrl={`https://ui-avatars.com/api/?name=Nomba Sandbox&background=FFF5CC&color=665200rounded=true`}
                    />
                  </MerchantLogo>
                  <Flex flexDirection="column">
                    <Typography fontSize="18px" fontWeight={500}>
                      {' '}
                      <span className="subtext">Pay</span> {sanboxOrder?.data?.order?.currency}{' '}
                      {formatAmount(sanboxOrder?.data?.order?.amount)}
                    </Typography>
                    <Typography fontSize="14px" color="n-grey3">
                      {sanboxOrder?.data?.order?.customerEmail}
                    </Typography>
                  </Flex>
                </MobileHeader>

                {!showMobile && (
                  <>
                    <MobilePaymentMethods>
                      <Box p="30px">
                        <Typography fontSize="18px" lineHeight="18px" color="n-grey5" fontWeight={500}>
                          Choose any of the payment method below to pay
                        </Typography>
                      </Box>
                      <MobilePaymentMethod
                        onClick={() => {
                          setActive(0);
                          setShowMobile(true);
                        }}
                        style={{ borderBottom: '1px solid #EEEEEE' }}
                      >
                        <Flex style={{ gap: '10px' }} alignItems="center">
                          {active === 0 ? <TransferActiveIcon /> : <TransferInActiveIcon />}
                          <Typography fontSize="16px" lineHeight="18px" color="n-grey5" fontWeight={500}>
                            Pay by Transfer
                          </Typography>
                        </Flex>
                        <ChevronRight />
                      </MobilePaymentMethod>

                      <MobilePaymentMethod
                        onClick={() => {
                          setActive(1);
                          setShowMobile(true);
                        }}
                      >
                        <Flex style={{ gap: '10px' }} alignItems="center">
                          {active === 1 ? <AtmCardActiveIcon /> : <AtmCardInActiveIcon />}
                          <Typography fontSize="16px" lineHeight="18px" color="n-grey5" fontWeight={500}>
                            Pay by Card
                          </Typography>
                        </Flex>
                        <ChevronRight />
                      </MobilePaymentMethod>
                    </MobilePaymentMethods>
                  </>
                )}

                {showMobile && (
                  <>
                    {active === 1 && (
                      <Card
                        changePaymentMethod={changePaymentMethod}
                        onMoveToNewScreen={onMoveToNewScreen}
                        orderDetails={sanboxOrder}
                      />
                    )}
                  </>
                )}
                <MobileFooter>
                  <span style={{ marginRight: '10px' }}>Secured by</span> <FooterLogoDark />
                </MobileFooter>
              </MobilePaymentWrapper>
            </>
          ) : (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="auto"
              mb="auto"
              style={{ height: '100%' }}
            >
              <Typography color="n-grey4" fontWeight={500}>
                Payment Is Currently Disabled
              </Typography>
            </Flex>
          )}
        </ModalV2>
      )}
      {currentScreen === 'FailedResponse' && <FailedResponse orderDetails={orderDetails} error={errorMsg} />}
      {currentScreen === 'SuccessResponse' && <SuccessResponse saveCard={false} orderDetails={sanboxOrder} />}
      {currentScreen === 'PhoneSuccess' && <PhoneSuccess orderDetails={orderDetails} />}
    </>
  );
}
