import { useEffect, useState } from 'react';
import { SavedCardBox, SavedCardsWrapper, TransferDetails, TrashIconWrapper } from '../style';
import { Flex, Box, Button } from 'rebass';
import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { errorResponse, successResponse } from '../../../utils/toast';
import { deleteSavedCard, payWithCard } from '../../../redux/action';
import { Orderdetails } from '../../../redux/slice';
import VisaCardSaved from '../../../assets/visa-saved';
import MasterCardSaved from '../../../assets/mastercard-saved';
import { ButtonText } from '../../styles';
import { SavedCard, capitalizeFirstLetter, formatAmount } from '../../../utils';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { ScreenTypes } from '../../../utils';
import VerveCard from '../../../assets/vervecard';
import Spinner from '../../../assets/spinner';

export default function SavedCards({
  cards,
  orderDetails,
  setCards,
  setCurrentSubScreen,
  onMoveToNewScreen,
}: {
  cards: SavedCard[];
  orderDetails: Orderdetails | null;
  setCards: React.Dispatch<React.SetStateAction<SavedCard[]>>;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
}) {
  const [selectedCard, setSelectedCard] = useState<SavedCard | null>(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);

  const dispatch = useDispatch<AppDispatch>();
  const { inProgress, cardPaymentResponse } = useSelector((state: RootState) => state.order);

  const handleSelectCard = (card: SavedCard, i: number) => () => {
    setSelectedCard(card);
    setSelectedCardIndex(i);
  };

  const handleDeleteCard = async (card: SavedCard) => {
    try {
      const result = await dispatch(deleteSavedCard(card.tokenKey));
      if (result?.payload?.data?.status) {
        const newCards = cards.filter((item) => item.tokenKey !== card.tokenKey);
        setCards(newCards);
        successResponse(result?.payload?.data?.message || 'Card deleted successfully');
        newCards.length === 0 && setCurrentSubScreen('CardInput');
      } else {
        errorResponse(result?.payload?.data?.message || 'An error occured, please try again');
      }
    } catch (error: any) {
      errorResponse(error.message || 'An error occured, please try again');
    }
  };

  const handleCardTransfer = async () => {
    if (!orderDetails || selectedCard === null) return;
    try {
      const payload = {
        orderReference: orderDetails?.data?.order?.orderId,
        tokenKey: selectedCard?.tokenKey,
      };
      await dispatch(payWithCard(payload));
    } catch (error: any) {
      errorResponse(error.message || 'An error occured, please try again');
    }
  };

  useEffect(() => {
    if (cardPaymentResponse === null) return;
    if (cardPaymentResponse?.data?.success) {
      onMoveToNewScreen('SuccessResponse', '', false);
    } else {
      onMoveToNewScreen('FailedResponse', cardPaymentResponse?.data?.message, false);
    }
  }, [cardPaymentResponse, onMoveToNewScreen]);

  return (
    <FadeIn delay={100}>
      <TransferDetails style={{ marginTop: '30px', textAlign: 'center' }}>
        <>
          <Typography color="n-dark" fontWeight={500}>
            Choose one of your saved card <br /> to continue payment
          </Typography>
          <SavedCardsWrapper>
            {cards.map((card, i) => (
              <Flex key={i} alignItems="center" mb="10px" style={{ gap: '16px' }}>
                <SavedCardBox
                  onClick={handleSelectCard(card, i)}
                  color={selectedCardIndex === i ? '#FFCC00' : '#EEEEEE'}
                >
                  {card.cardType.toLowerCase() === 'visa' ? (
                    <VisaCardSaved />
                  ) : card.cardType.toLowerCase() === 'mastercard' ? (
                    <MasterCardSaved />
                  ) : (
                    <VerveCard />
                  )}
                  <Typography fontSize="14px" lineHeight="18px">
                    {capitalizeFirstLetter(card.cardType)} ending in **** {card.cardPan}
                  </Typography>
                </SavedCardBox>
                <TrashIconWrapper onClick={() => handleDeleteCard(card)}>
                  <NIcons icon={IconsEnum.OUTLINE_TRASH} color="n-red" width="14" height="14" />
                </TrashIconWrapper>
              </Flex>
            ))}
          </SavedCardsWrapper>

          {selectedCard !== null ? (
            <Button
              onClick={handleCardTransfer}
              textAlign="center"
              mt="20px"
              bg="#FFCC00"
              color="#121212"
              variant="large"
              width="100%"
              height="50px"
              className="cursor-pointer"
              disabled={inProgress}
              opacity={inProgress ? '0.4' : '1'}
            >
              {inProgress ? (
                <Spinner />
              ) : (
                <ButtonText>
                  Pay {orderDetails?.data?.order?.currency} {formatAmount(orderDetails?.data?.order?.amount)}
                </ButtonText>
              )}
            </Button>
          ) : (
            <Button
              disabled={true}
              opacity="1"
              mt="20px"
              bg="#FFF5CC"
              color="#ccc"
              variant="large"
              width="100%"
              height="50px"
              className="cursor-pointer"
            >
              <Typography color="n-grey3">
                Pay {orderDetails?.data?.order?.currency} {formatAmount(orderDetails?.data?.order?.amount)}
              </Typography>
            </Button>
          )}
          <Box mt="20px" className="cursor-pointer" onClick={() => setCurrentSubScreen('CardInput')}>
            <Typography color="n-grey4" fontWeight={500}>
              Pay via another card
            </Typography>
          </Box>
        </>
      </TransferDetails>
    </FadeIn>
  );
}
