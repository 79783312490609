import { OtpInput, Typography } from "kudi-component-library";
import { useEffect, useState, Suspense } from "react";
import { Box, Flex } from 'rebass'
import Modal from "../../Modal";
import CloseModalNew from "../../../assets/closemodal-new";
import PhoneOtpIcon from "../../../assets/phone-otp";
import { CountdownTimer } from "../../../utils/Countdown";
import { ScreenTypes, SuccessOrderDetails, handleCallbackUrl } from "../../../utils";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { saveUserCard, verifyPhone } from "../../../redux/action";
import ConfirmModal from "../../ConfirmModal";

export default function VerifyPhone({ onMoveToNewScreen, setShowVerify, otpMessage, hasSavedCards, orderDetails, phoneNumber }: { setShowVerify: React.Dispatch<React.SetStateAction<boolean>>, onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void, phoneNumber: string, hasSavedCards: boolean | undefined, otpMessage: string, orderDetails: SuccessOrderDetails | undefined }) {
    const [otpValue, setOtpValue] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [otpMsg, setOtpMsg] = useState<string>('');
    const [timer, setTimer] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const { confirmingPayment, saveCardResponse } = useSelector((state: RootState) => state.order);

    function handleChange(val: string) {
        setOtpValue(val);
        setOtpError('')
        if (val.length === 4) {
            handleSubmit(val);
        }
    }

    const handleSubmit = (val: string) => {
        if (!val) {
            setOtpError('OTP is required');
            return;
        }
        if (!orderDetails) return;
        const payload = {
            phoneNumber,
            otp: val,
            orderReference: orderDetails?.orderId
        }
        dispatch(saveUserCard(payload));
    }

    const handleClose = () => {
        setShowModal(false)
    }
    const confirm = () => {
        if (orderDetails && orderDetails?.callbackUrl) {
            handleCallbackUrl(orderDetails?.callbackUrl, orderDetails?.orderId, orderDetails?.orderReference);
        }
    }

    useEffect(() => {
        if (!saveCardResponse) return;
        if (saveCardResponse.data.success) {
            onMoveToNewScreen("PhoneSuccess", "", false);
        } else {
            setOtpError(saveCardResponse?.data?.message || "An error occured, please try again")
        }

    }, [dispatch, onMoveToNewScreen, saveCardResponse])

    const resendOtp = async () => {
        try {
            if (!orderDetails) return;
            setLoading(true);
            const payload = {
                orderReference: orderDetails?.orderId
            }
            const result = await dispatch(verifyPhone(payload))
            setLoading(false);
            setTimer(1);
            setOtpMsg(result?.payload?.data?.message);
        } catch (error: any) {
            setLoading(false);
            setOtpError(error.message || "An error occured, please try again")
        }
    }

    return (
        <Flex justifyContent={"center"} alignItems={"center"}>
            <Suspense fallback={<Loader/>}>
                <Modal showModal={true} onClose={handleClose}>
                    <Flex justifyContent="flex-end" mb="10px">
                        <CloseModalNew onClick={() => setShowModal(true)} style={{ cursor: "pointer" }} />
                        {showModal &&
                            <ConfirmModal confirm={confirm} showModal={showModal} handleClose={handleClose} />
                        }
                    </Flex>
                    {confirmingPayment || loading ?
                        <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ gap: "10px" }}>
                            <Loader />
                            <Typography color="n-grey4" fontWeight={500}>
                                Please wait
                            </Typography>
                        </Flex>
                        :
                        <Flex p="50px" mb="30px" bg="#FFFFFF" height="100%" style={{ borderRadius: "16px" }} flexDirection="column" justifyContent="center" alignItems="center">
                            <PhoneOtpIcon />
                            <Flex justifyContent="center" textAlign="center" mt="10px">
                                {hasSavedCards ?
                                    <Typography color="n-grey4" lineHeight="18px" fontWeight={600}>
                                        {otpMessage || otpMsg}
                                    </Typography>
                                    :
                                    <Typography color="n-grey4" lineHeight="18px" fontWeight={600}>
                                        Enter One time passcode (OTP) sent <br /> to {phoneNumber}
                                    </Typography>
                                }
                            </Flex>

                            <Box mt="40px">
                                <OtpInput
                                    width="100%"
                                    inputsNumber={4}
                                    name="token"
                                    value={otpValue}
                                    onChange={(e) => handleChange(e.target.value)}
                                    error={otpError}
                                />
                            </Box>

                            {timer > 0 ?
                                <Flex mt="30px" alignItems="center" style={{ gap: "5px" }}>
                                    <Typography color='dark' fontWeight={500}>Resend OTP in
                                    </Typography>
                                    <CountdownTimer  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0), color: "dark", fontSize: "14px" }} />
                                    <Typography color='dark' fontWeight={500}>secs
                                    </Typography>
                                </Flex>
                                :
                                <Box mt="30px" onClick={() => resendOtp()}>
                                    <Typography className='cursor-pointer' color='n-yellow8' fontWeight={500}>Resend OTP
                                    </Typography>
                                </Box>
                            }
                            {!hasSavedCards &&
                                <Box onClick={() => setShowVerify(false)} display="flex" width="100%" justifyContent="center" mt="40px">
                                    <Typography className="cursor-pointer">Change Phone Number</Typography>
                                </Box>
                            }
                        </Flex>
                    }
                </Modal>
            </Suspense>
        </Flex>

    )
}
