import { Suspense, useState } from "react";
import CheckoutModal from "./CheckoutModal";
import Loader from "./Loader";

export default function CheckoutForm() {
    const [showModal] = useState<boolean>(true);

    return (
        <Suspense fallback={<Loader/>}>
            {showModal && <CheckoutModal showModal={showModal} />}
        </Suspense>
    );
}
