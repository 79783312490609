import { useEffect, useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../assets';
import { formatAmount, ScreenTypes } from '../utils';
import { CountdownTimer } from '../utils/Countdown';
import { AppHeader, ButtonText, InfoText, PaymentMethodBox, WhiteBox } from './styles';
import { KtaOrderResponse } from '../redux/slice';
import { AppDispatch, RootState } from '../store';
import { confirmBankTransfer } from '../redux/action';
import Loader from './Loader';

export default function BankTransferWait({
  orderReference,
  amount,
  currency,
  bankDetails,
  onMoveToNewScreen,
}: {
  orderReference: string | undefined;
  amount: number | undefined;
  currency?: string;
  bankDetails: KtaOrderResponse | null;
  onMoveToNewScreen: (newscreen: ScreenTypes, error?: string) => void;
}) {
  const [timer, setTimer] = useState<number>(10);
  const dispatch = useDispatch<AppDispatch>();
  const { confirmResponse } = useSelector((state: RootState) => state.order);
  const [showRetry, setShowRetry] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const handleVerify = () => {
    setTimer(5);
    setShowRetry(false);
    setRetryCount(2);
    orderReference && dispatch(confirmBankTransfer({ orderReference }));
  };

  useEffect(() => {
    orderReference && dispatch(confirmBankTransfer({ orderReference }));
  }, [dispatch, orderReference]);

  useEffect(() => {
    if (confirmResponse === null) {
      return;
    }
    if (confirmResponse?.data.message === 'PAYMENT SUCCESSFUL') {
      sessionStorage.setItem('showResponse', 'true');
      onMoveToNewScreen('SuccessResponse');
      return;
    }
    if (timer === 0 && retryCount === 2 && confirmResponse?.data.message !== 'PAYMENT SUCCESSFUL') {
      onMoveToNewScreen('FailedResponse', confirmResponse?.data.message);
      return;
    }
  }, [confirmResponse, timer, retryCount, onMoveToNewScreen]);

  useEffect(() => {
    // retry confirmation of payment every 30 seconds
    const interval = setInterval(() => {
      orderReference && dispatch(confirmBankTransfer({ orderReference }));
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, orderReference]);

  useEffect(() => {
    if (timer === 0) {
      setRetryCount(1);
      setShowRetry(true);
    }
  }, [timer]);

  return (
    <Box>
      <Flex flexDirection="column" alignItems="flex-start">
        <AppHeader>Bank transfer</AppHeader>

        {showRetry ? (
          <PaymentMethodBox>
            <Flex py="30px" flexDirection="column" justifyContent="center" alignItems="center">
              <Alert />
              <InfoText mt="30px" textAlign="center">
                We couldn't confirm your <br />
                payment of
                <span style={{ fontWeight: 700 }}> {amount && `${currency} ${formatAmount(amount)}`}</span>
              </InfoText>
              <Button
                onClick={() => handleVerify()}
                textAlign="center"
                mt="30px"
                bg="#FFCC00"
                color="#121212"
                variant="large"
                width="fit-content"
                height="40px"
                className="cursor-pointer"
              >
                <ButtonText>Wait an additional 5 miutes</ButtonText>
              </Button>
            </Flex>
          </PaymentMethodBox>
        ) : (
          <PaymentMethodBox>
            <Box py="20px">
              <Flex justifyContent="center" alignItems="center" flexDirection="column">
                <InfoText fontSize="14px" textAlign="center" fontWeight={700}>
                  PLEASE DO NOT REFRESH
                </InfoText>

                <Loader />

                <InfoText mt="20px" textAlign="center">
                  Please hold on while we confirm <br />
                  your payment of
                  <span style={{ fontWeight: 700 }}> {amount && `${currency} ${amount}`}</span>
                </InfoText>

                <Flex justifyContent="center" mt="20px">
                  <WhiteBox>
                    Wait time - <CountdownTimer {...{ minutes: timer, seconds: 0, cb: () => setTimer(0) }} />
                  </WhiteBox>
                </Flex>
              </Flex>
              <Flex justifyContent="center" mt="20px">
                <WhiteBox width="100%" style={{ padding: '20px 0' }}>
                  <InfoText textAlign="center" fontWeight={700}>
                    {bankDetails?.data?.accountNumber} - {bankDetails?.data?.bankName}
                  </InfoText>
                  <InfoText textAlign="center">{bankDetails?.data?.accountName}</InfoText>
                </WhiteBox>
              </Flex>
            </Box>
          </PaymentMethodBox>
        )}
      </Flex>
    </Box>
  );
}
