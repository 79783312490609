import { NTheme } from "kudi-component-library"
import { ThemeProvider } from "styled-components"
import { AppBody } from "../styles"
import { Suspense, useState } from "react"
import MainModal from "./Main"
import Loader from "../Loader";


export default function Landing() {
    const [showModal] = useState<boolean>(true);

    return !showModal ? <Loader /> : (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Suspense fallback={<Loader/>}>
                    {showModal && <MainModal showModal={showModal} />}
                </Suspense>
            </AppBody>
        </ThemeProvider>
    )
}
