import { NTheme, Typography } from 'kudi-component-library';
import { useEffect, useState, Suspense } from 'react';
import { Button, Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import Modal from '../../Modal';
import { SuccessOrderDetails, handleCallbackUrl, ScreenTypes, formatAmount } from '../../../utils';
import { AppBody, AppHeaderText, ButtonText, InfoText } from '../../styles';
import { CardInputWrapper, CardNumberInput, ErrorMessage, FormRow, FormWrapper, PhoneNumberWrapper } from '../style';
import SuccessNewIcon from '../../../assets/success-new';
import VerifyPhone from './VerifyPhoneOtp';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { authenticatePhone, verifyPhone } from '../../../redux/action';
import { Spinner, Star } from '../../../assets';
import CloseModalNew from '../../../assets/closemodal-new';
import Loader from '../../Loader';

interface SuccessResponseProps {
  onMoveToNewScreen?: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  saveCard: boolean;
  hasSavedCards?: boolean;
  orderDetails: SuccessOrderDetails | undefined;
  customId?: string;
  redirectUrl?: string;
  showCustomModal?: () => void;
}

export default function SuccessResponse({
  onMoveToNewScreen,
  saveCard,
  orderDetails,
  hasSavedCards,
  customId,
  redirectUrl,
  showCustomModal,
}: SuccessResponseProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otpMessage, setOtpMessage] = useState<string>('');
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const { inProgress } = useSelector((state: RootState) => state.order);

  const handleClose = () => {
    if (orderDetails && orderDetails?.callbackUrl) {
      handleCallbackUrl(orderDetails?.callbackUrl, orderDetails?.orderId, orderDetails?.orderReference);
    } else if (orderDetails && redirectUrl) {
      handleCallbackUrl(redirectUrl, orderDetails?.orderId, orderDetails?.orderReference);
    } else {
      showCustomModal && showCustomModal();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (!saveCard) {
      timeoutId = setTimeout(() => {
        if (orderDetails && redirectUrl) {
          handleCallbackUrl(redirectUrl, orderDetails?.orderId, orderDetails?.orderReference);
        } else if (orderDetails && orderDetails?.callbackUrl) {
          handleCallbackUrl(orderDetails?.callbackUrl, orderDetails?.orderId, orderDetails?.orderReference);
        } else {
          showCustomModal && showCustomModal();
        }
      }, 3000);
    }

    // Cleanup function to clear timeout if component unmounts or saveCard changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [orderDetails, saveCard, customId]);

  const handleVerify = async () => {
    if (!phoneNumber || !orderDetails?.orderId) return;

    const payload = {
      phoneNumber,
      orderReference: orderDetails?.orderId,
    };
    const result = await dispatch(authenticatePhone(payload));
    if (result?.payload?.data?.success) {
      setShowVerify(true);
    }
  };
  const handleVerifyExistingPhone = async () => {
    if (!orderDetails?.orderId) return;
    setLoading(true);
    const payload = {
      orderReference: orderDetails?.orderId,
    };
    const result = await dispatch(verifyPhone(payload));
    if (result?.payload?.data?.success) {
      setShowVerify(true);
      setOtpMessage(result?.payload?.data?.message || '');
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        {showVerify && orderDetails && onMoveToNewScreen ? (
          <VerifyPhone
            otpMessage={otpMessage}
            hasSavedCards={hasSavedCards && hasSavedCards}
            onMoveToNewScreen={onMoveToNewScreen}
            phoneNumber={phoneNumber}
            orderDetails={orderDetails}
            setShowVerify={setShowVerify}
          />
        ) : (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Suspense fallback={<Loader />}>
              <Modal showModal={true} onClose={handleClose}>
                <Flex justifyContent="end">
                  <CloseModalNew onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </Flex>
                <Flex
                  bg="#FFFFFF"
                  height="100%"
                  style={{ borderRadius: '16px' }}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SuccessNewIcon />
                  <AppHeaderText mt="10px">
                    Your Payment has been <br />
                    confirmed successfully
                  </AppHeaderText>
                  {saveCard ? (
                    <PhoneNumberWrapper>
                      {hasSavedCards ? (
                        <InfoText color="n-grey4" fontWeight={500} fontSize="14px" textAlign="center" mt="16px">
                          Click the button below to <br />
                          verify your phone number
                        </InfoText>
                      ) : (
                        <InfoText color="n-grey4" fontWeight={500} fontSize="14px" textAlign="center">
                          Enter phone number to save your <br />
                          card for future use
                        </InfoText>
                      )}

                      <FormWrapper>
                        {!hasSavedCards && (
                          <FormRow>
                            <CardInputWrapper
                              style={{
                                border: `1px solid ${NTheme.nColors['n-grey1']}`,
                                background: NTheme.nColors['n-light'],
                              }}
                            >
                              <Typography fontSize="12px" color="n-grey5">
                                Phone number
                              </Typography>
                              <CardNumberInput
                                key="phonenumber"
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={(e) => handleChange(e)}
                              />
                            </CardInputWrapper>
                          </FormRow>
                        )}
                        {hasSavedCards ? (
                          <Button
                            onClick={() => handleVerifyExistingPhone()}
                            textAlign="center"
                            mt="20px"
                            bg={NTheme.nColors['n-yellow']}
                            color={NTheme.nColors['n-dark']}
                            variant="large"
                            width="100%"
                            height="50px"
                            className="cursor-pointer"
                          >
                            {loading ? <Spinner /> : <ButtonText>Verify</ButtonText>}
                          </Button>
                        ) : !phoneNumber ? (
                          <Button
                            textAlign="center"
                            opacity="0.4"
                            mt="20px"
                            bg={NTheme.nColors['n-yellow']}
                            color={NTheme.nColors['n-dark']}
                            variant="large"
                            width="100%"
                            height="50px"
                            className="cursor-pointer"
                            disabled
                          >
                            <ButtonText>Verify</ButtonText>
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleVerify()}
                            textAlign="center"
                            mt="20px"
                            bg={NTheme.nColors['n-yellow']}
                            color={NTheme.nColors['n-dark']}
                            variant="large"
                            width="100%"
                            height="50px"
                            className="cursor-pointer"
                          >
                            {inProgress ? <Spinner /> : <ButtonText>Verify</ButtonText>}
                          </Button>
                        )}
                      </FormWrapper>
                    </PhoneNumberWrapper>
                  ) : (
                    <>
                      <ErrorMessage>
                        <InfoText fontSize="12px" textAlign="center">
                          Your payment of{' '}
                          <span style={{ fontWeight: 700 }}>
                            {orderDetails?.currency} {formatAmount(orderDetails?.amount)}
                          </span>{' '}
                          to <span style={{ fontWeight: 700 }}>{orderDetails?.businessName}</span> has been confirmed.
                          You will now be redirected to your merchant's site. Thank you.
                        </InfoText>
                      </ErrorMessage>
                      <div
                        style={{
                          marginTop: '100px',
                          padding: '8px',
                          background: 'linear-gradient(to right, #FFF5CC, #FFFCF2)',
                          borderRadius: '8px',
                          fontSize: '12',
                          lineHeight: '1.5',
                          border: '1px solid #FFEB99',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Star />
                        </div>
                        <div
                          style={{
                            paddingLeft: '10px',
                            paddingRight: '25px',
                          }}
                        >
                          Experience seamless transactions and earn rewards effortlessly with Nomba. Fast, secure, and
                          rewarding—get more with the Nomba app!
                          <a
                            href="https://app.nom.ba/V9Md/ch"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: 'inherit',
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Sign up to Nomba now
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </Flex>
              </Modal>
            </Suspense>
          </Flex>
        )}
      </AppBody>
    </ThemeProvider>
  );
}
